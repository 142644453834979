import React, { useEffect, useRef } from 'react';

/**
 * props:
 * - id
 * - text
 * - isChecked
 * - onChange
 */
function SwitchInput(props) {

    const selectRef = useRef('');

    function onChange(event) {

        let listar_secao = 'S';

        if (selectRef.current.checked) {
            listar_secao = 'S'
        } else {
            listar_secao = 'N'
        }

        props.onChange({ target: { id: props.id, value: listar_secao } });
    }

    useEffect(() => {

        if (props.isChecked === 'S') {
            selectRef.current.checked = true;
        } else {
            selectRef.current.checked = false;
        }

    }, [props.isChecked])

    return (
        <div>
            <div className="form-check form-switch">
                <input ref={selectRef} className="form-check-input" type="checkbox" id={props.id} onChange={onChange} />
                <label className="form-check-label" htmlFor={props.id}>{props.text}</label>
            </div>
        </div>
    )
}

export default SwitchInput;