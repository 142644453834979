import React, { useEffect, useState } from "react";
import "./GerenciamentoLoja.scss";
import {
  getDataMenu,
  storeCoverMenu,
  storeLogoMenu,
  updateCoverMenu,
  updateDataMenu,
  updateLogoMenu,
  updateStatusStore,
} from "../../components/helpers/Api";
import { toast } from "react-toastify";
import { Load } from "../../components/widgets/spinner/Spinner";
import { formatarMoeda } from "../../utils/utils";
import moment from "moment";

const GerenciamentoLoja = () => {
  const [cover, setCover] = useState("");
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [loadingData, setLoadingData] = useState(false);
  const [status, setStatus] = useState("");
  const [logo, setLogo] = useState("");
  const [loadingLogo, setLoadingLogo] = useState(false);
  const [numero, setNumero] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [logradouro, setlogradouro] = useState("");
  const [uf, setUf] = useState("");
  const [minimumPrice, setMinimumPrice] = useState(0);
  const [taxaEntrega, setTaxaEntrega] = useState(0);
  const [horaAbertura, setHoraAbertura] = useState("");
  const [horaFechamento, setHoraFechamento] = useState("");

  function handleCreateImage(file) {
    if (file) {
      if (
        file.type !== "image/png" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/jpg" &&
        file.type !== "image/webp"
      ) {
        return toast.warning("Tipo de arquivo não suportado !!");
      }
    }
    if (file.size > 100000) {
      return toast.warning("A imagem não pode exceder 100kb !!");
    }

    setLoading(true);
    const formData = new FormData();

    formData.append("imagem", file);

    storeCoverMenu(formData)
      .then((res) => {
        setCover(res.data.capa);
        if (res) {
          toast.success("Capa carregada com sucesso!!");
        }
      })
      .catch((error) => {
        toast.error("Erro ao carregar a capa, tente novamente!!");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleGetDataMenu() {
    setLoading(true);
    getDataMenu()
      .then((res) => {
        console.log(res)
        setCover(res.data.data.capa);
        setName(res.data.data.razao_social);
        setStatus(res.data.data.status);
        setLogo(res.data.data.logo);
        setMinimumPrice(res.data.data.preco_minimo);
        setTaxaEntrega(res.data.data.taxa_entrega);
        setCidade(res.data.data.cidade);
        setlogradouro(res.data.data.logradouro);
        setBairro(res.data.data.bairro);
        setUf(res.data.data.uf);
        setNumero(res.data.data.numero);
        const horarioAbertura = moment.utc(res.data.data.horario_abertura).format('HH:mm');
        setHoraAbertura(horarioAbertura)

        setHoraFechamento(moment.utc(res.data.data.horario_fechamento).format('HH:mm'))
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleUpdateCoverMenu(file) {
    if (file) {
      if (
        file.type !== "image/png" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/jpg" &&
        file.type !== "image/webp"
      ) {
        return toast.warning("Tipo de arquivo não suportado !!");
      }
    }
    if (file.size > 100000) {
      return toast.warning("A imagem não pode exceder 100kb !!");
    }
    setLoading(true);
    const formData = new FormData();

    formData.append("imagem", file);
    updateCoverMenu(formData, cover)
      .then((res) => {
        setCover(res.data.capa);
        if (res) {
          toast.success("Capa atualizada com sucesso!!");
        }
      })
      .catch((error) => {
        toast.error("Erro ao atualizar a capa, tente novamente!!");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleStoreLogoMenu(file) {
    if (file) {
      if (
        file.type !== "image/png" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/jpg" &&
        file.type !== "image/webp"
      ) {
        return toast.warning("Tipo de arquivo não suportado !!");
      }
    }
    if (file.size > 100000) {
      return toast.warning("A imagem não pode exceder 100kb !!");
    }
    setLoadingLogo(true);
    const formData = new FormData();

    formData.append("imagem", file);
    storeLogoMenu(formData)
      .then((res) => {
        setLogo(res.data.logo);
        if (res) {
          toast.success("Logo carregado com sucesso!!");
        }
      })
      .catch((error) => {
        toast.error("Erro ao carregar o logo, tente novamente!!");
      })
      .finally(() => {
        setLoadingLogo(false);
      });
  }

  function handleUpdateLogoMenu(file) {
    if (file) {
      if (
        file.type !== "image/png" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/jpg" &&
        file.type !== "image/webp"
      ) {
        return toast.warning("Tipo de arquivo não suportado !!");
      }
    }
    if (file.size > 100000) {
      return toast.warning("A imagem não pode exceder 100kb !!");
    }
    setLoadingLogo(true);
    const formData = new FormData();

    formData.append("imagem", file);
    updateLogoMenu(formData, logo)
      .then((res) => {
        setLogo(res.data.logo);
        if (res) {
          toast.success("Logo atualizada com sucesso!!");
        }
      })
      .catch((error) => {
        toast.error("Erro ao atualizar o logo, tente novamente!!");
      })
      .finally(() => {
        setLoadingLogo(false);
      });
  }

  function handleStatusStore(status) {
    let data = {
      status,
    };
    updateStatusStore(data)
      .then((res) => {
        setStatus(res.data.data.status);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => { });
  }

  useEffect(() => {
    handleGetDataMenu();
  }, []);

  function handleUpdateDataMenu() {
    setLoadingData(true);

    if (name?.length < 3) {
      setLoadingData(false);
      return toast.warning("O nome da loja deve conter mais caracteres!!");
    }

    if (horaAbertura === horaFechamento) {
      setLoadingData(false);
      return toast.warning("Hora de abertura e fechamento não podem ser iguais!!");
    }

    const data = {
      razao_social: name,
      preco_minimo: minimumPrice,
      taxa_entrega: taxaEntrega,
      numero: numero,
      bairro: bairro,
      cidade: cidade,
      uf: uf,
      logradouro: logradouro,
      horario_abertura: moment(horaAbertura, 'HH:mm').format('HH:mm'),
      horario_fechamento: moment(horaFechamento, 'HH:mm').format('HH:mm')

    };

    updateDataMenu(data)
      .then((res) => {
        setName(res.data.data.razao_social);
        if (res) {
          toast.success("Dados atualizados com sucesso!!");
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoadingData(false);
      });
  }

  function handleCash(event, index) {
    let value = formatarMoeda(event);
    value = value.replace(".", "");
    value = value.replace(",", ".");
    if (value > 0) {
      setMinimumPrice(value);
    } else {
      setMinimumPrice(0);
    }
  }

  function handleCashTaxaEntrega(event, index) {
    let value = formatarMoeda(event);
    value = value.replace(".", "");
    value = value.replace(",", ".");
    if (value > 0) {
      setTaxaEntrega(value);
    } else {
      setTaxaEntrega(0);
    }
  }

  return (
    <main id="menu">
      <section className="default-section">
        <p className="title">Status da loja</p>
        <p className={`status ${status ? "status--opened" : "status--closed"}`}>
          {status ? "Aberto" : "Fechado"}
        </p>

        <div className="d-flex justify-content-between p-5">
          <button
            disabled={!status}
            className="closed"
            onClick={() => handleStatusStore(false)}
            style={{
              background: !status ? "#778899" : "",
              color: !status ? "#fff" : "",
              border: !status ? "none" : "",
            }}
          >
            {!loadingData && (
              <span className="indicator-label">Fechar Loja</span>
            )}
            {loadingData && (
              <span className="indicator-progress" style={{ display: "block" }}>
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
          <button
            disabled={status}
            className="opened"
            onClick={() => handleStatusStore(true)}
            style={{
              background: status ? "#778899" : "",
              color: status ? "#fff" : "",
              border: status ? "none" : "",
            }}
          >
            {!loadingData && (
              <span className="indicator-label">Abrir Loja</span>
            )}
            {loadingData && (
              <span className="indicator-progress" style={{ display: "block" }}>
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      </section>
      <section className="menu-logo default-section">
        <p className="title">Logo para a loja</p>
        {loadingLogo ? (
          <Load />
        ) : (
          <>
            <img
              height={100}
              width={100}
              crossOrigin="anonymous"
              src={
                logo
                  ? process.env.REACT_APP_API_URL + `/${logo}`
                  : "/img/image.png"
              }
              alt=""
            />
            {logo?.length > 0 ? (
              <label htmlFor="" className="form-control w-90 mx-auto">
                Atualizar imagem:{" "}
                <input
                  className="form-control "
                  type="file"
                  accept="image/*"
                  id="formFile"
                  onChange={(e) => handleUpdateLogoMenu(e.target.files[0])}
                  multiple
                />
              </label>
            ) : (
              <label htmlFor="" className="form-control w-90 mx-auto">
                Inserir imagem:{" "}
                <input
                  className="form-control"
                  type="file"
                  accept="image/*"
                  id="formFile"
                  onChange={(e) => handleStoreLogoMenu(e.target.files[0])}
                  multiple
                />
              </label>
            )}
          </>
        )}
      </section>
      <section className="menu-image default-section">
        <p className="title">Capa para a loja</p>
        {loading ? (
          <Load />
        ) : (
          <>
            <img
              crossOrigin="anonymous"
              src={
                cover
                  ? process.env.REACT_APP_API_URL + `/${cover}`
                  : "/img/image.png"
              }
              alt=""
            />
            {cover?.length > 0 ? (
              <label htmlFor="" className="form-control w-90 mx-auto">
                Atualizar imagem:{" "}
                <input
                  className="form-control "
                  type="file"
                  accept="image/*"
                  id="formFile"
                  onChange={(e) => handleUpdateCoverMenu(e.target.files[0])}
                  multiple
                />
              </label>
            ) : (
              <label htmlFor="" className="form-control w-90 mx-auto">
                Inserir imagem:{" "}
                <input
                  className="form-control"
                  type="file"
                  accept="image/*"
                  id="formFile"
                  onChange={(e) => handleCreateImage(e.target.files[0])}
                  multiple
                />
              </label>
            )}
          </>
        )}
      </section>
      <section className="menu-information default-section">
        <p className="title">Informações para a loja</p>
        <label htmlFor="" className="form-control w-90 mx-auto">
          Nome da loja:{" "}
          <input
            type="text"
            className="form-control"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>
        <label htmlFor="" className="form-control w-90 mx-auto mt-3">
          Endereço da loja:{" "}
          <div className="container mt-3">
            <form>
              <div className="form-row ">
                <div className="row col">
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Logradouro"
                      value={logradouro}
                      onChange={(e) => setlogradouro(e.target.value)}
                    />
                  </div>
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Número"
                      value={numero}
                      onChange={(e) => setNumero(e.target.value)}
                    />
                  </div>
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Bairro"
                      value={bairro}
                      onChange={(e) => setBairro(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row" style={{ paddingTop: "10px" }}>
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Cidade"
                      value={cidade}
                      onChange={(e) => setCidade(e.target.value)}
                    />
                  </div>
                  <div className="col-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="UF"
                      value={uf}
                      onChange={(e) => setUf(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </label>
        <label htmlFor="" className="form-control w-90 mx-auto mt-3">
          Valor Mínimo:
          <input
            type="text"
            className="form-control"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            min={0}
            value={minimumPrice}
            onChange={(e) => handleCash(e)}
          />
        </label>
        <label htmlFor="" className="form-control w-90 mx-auto mt-3">
          Taxa de entrega:
          <input
            type="text"
            className="form-control"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            min={0}
            value={taxaEntrega}
            onChange={(e) => handleCashTaxaEntrega(e)}
          />
        </label>

        <label htmlFor="" className="form-control w-90 mx-auto mt-3">
          Hora de Abertura:
          <input
            type="time"
            className="form-control"
            value={horaAbertura}
            onChange={(e) => setHoraAbertura(e.target.value)}
          />
        </label>
        <label htmlFor="" className="form-control w-90 mx-auto mt-3">
          Hora de Fechamento:
          <input
            type="time"
            className="form-control"
            value={horaFechamento}
            onChange={(e) => setHoraFechamento(e.target.value)}
          />
        </label>

        <div className="w-100 d-flex">
          <button
            className="save mx-auto w-90"
            onClick={() => handleUpdateDataMenu()}
          >
            {!loadingData && <span className="indicator-label">Salvar</span>}
            {loadingData && (
              <span className="indicator-progress" style={{ display: "block" }}>
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      </section>
    </main>
  );
};

export default GerenciamentoLoja;
