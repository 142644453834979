/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-computed-key */
/* eslint-disable array-callback-return */

import React, { useEffect, useState } from "react";
import { Box, Modal } from "@mui/material";
import "./Modals.scss";
import { toast } from "react-toastify";
import { getProductTamanho, getProductSabores, getProductAdd } from "../../../components/helpers/Api";

const SelectProductModal = ({ ...props }) => {
  function handleModal(e) {
    if (e.nativeEvent.target.id === "box-mui-select") {
      props.setModalSelectFalse();
    }
  }
  const [amountProduct, setAmountProduct] = useState(1);
  const [observation, setObservation] = useState("");
  const [additionals, setAdditionals] = useState([]);
  const [hasIndexies, setHasIndexies] = useState([]);
  const [limitSection, setLimitSection] = useState([]);
  const [tamanhos, setTamanhos] = useState([]);
  const [selectedTamanho, setSelectedTamanho] = useState(null);
  const [sabores, setSabores] = useState([]);
  const [selectedSabores, setSelectedSabores] = useState([]);
  const [saborString, setSaborString] = useState("");
  const [sumAdd, setSumAdd] = useState(0)

  const [data, setData] = useState([]);
  let [price, setPrice] = useState(0);
  const [originalPriceProduct, setOriginalPriceProduct] = useState(0);

  function handleCart() {
    let localStorageCart = JSON.parse(localStorage.getItem("cart")) || [];
    const cartItem = {
      observation,
      amount: amountProduct,
      name: selectedTamanho
        ? `${props.product.descricao} (${saborString}) - ${selectedTamanho.descricao}`
        : props.product.descricao,
      price,
      image: props.product.imagem,
      cod_pk_produto: props.product.cod_produto,
      additionals,
      additionalsPrice: price - originalPriceProduct,
    };

    localStorageCart.push(cartItem);
    localStorage.setItem("cart", JSON.stringify(localStorageCart));
    props.setModalSelectFalse();
  }
  useEffect(() => {
    console.log("Updated Price:", sumAdd);
  }, [sumAdd]);

  function handleAddAdditionals(indexSection, indexProduct, product, isMinus) {

    const productPrice = product.preco_venda_vista;

    const newSumAdd = sumAdd + product.preco_venda_vista;
    setSumAdd(newSumAdd);
    setPrice((prevPrice) => prevPrice + product.preco_venda_vista);



    const updatedData = [...data];
    if (!updatedData[indexSection].tb_produtos[indexProduct].quantidade) {
      updatedData[indexSection].tb_produtos[indexProduct].quantidade = 0;
    }
    updatedData[indexSection].tb_produtos[indexProduct].quantidade += 1;
    updatedData[indexSection].quantidade += 1;

    if (updatedData[indexSection].quantidade === updatedData[indexSection].web_quantidade) {
      setLimitSection([...limitSection, indexSection]);
    }


    setData(updatedData);
    setAdditionals((prev) => [
      ...prev.filter((item) => item.cod_produto !== product.cod_produto),
      {
        amount: updatedData[indexSection].tb_produtos[indexProduct].quantidade,
        name: `${product.descricao} -`,
        price: productPrice,
        cod_produto: product.cod_produto,
        cod_secao: product.cod_fk_secao,
      },
    ]);
  }

  function handleSubAdditionals(indexSection, indexProduct, product) {
    const updatedData = [...data];
    const productPrice = product.preco_venda_vista;

    // Verifica se a quantidade do produto é maior que 0 antes de decrementar
    if (updatedData[indexSection].tb_produtos[indexProduct].quantidade > 0) {
      updatedData[indexSection].tb_produtos[indexProduct].quantidade -= 1;
      updatedData[indexSection].quantidade -= 1;

      let updateSum = sumAdd - productPrice
      setSumAdd(updateSum)
      setPrice((prevPrice) => prevPrice - productPrice);

      if (updatedData[indexSection].quantidade < updatedData[indexSection].web_quantidade) {
        setLimitSection((prev) => prev.filter((index) => index !== indexSection));
      }

      setAdditionals((prev) => {
        const updatedAdditionals = [...prev];
        const additionalIndex = updatedAdditionals.findIndex(
          (item) => item.cod_produto === product.cod_produto
        );

        if (additionalIndex !== -1) {
          if (updatedData[indexSection].tb_produtos[indexProduct].quantidade > 0) {
            updatedAdditionals[additionalIndex].amount = updatedData[indexSection].tb_produtos[indexProduct].quantidade;
          } else {
            updatedAdditionals.splice(additionalIndex, 1);
          }
        }

        return updatedAdditionals;
      });

      setData(updatedData);
    }
  }


  function handleTamanhoChange(event) {
    const tamanhoSelecionado = tamanhos.find(
      (tamanho) => tamanho.descricao === event.target.value
    );
    if (tamanhoSelecionado) {

      setSelectedTamanho(tamanhoSelecionado);

      setPrice(tamanhoSelecionado.valor + sumAdd);
      if (selectedSabores.length > 0) {
        fetchSaborPrices(selectedSabores, tamanhoSelecionado);
      }
    }
  }

  function handleSaborChange(event) {
    const { value, checked } = event.target;

    setSelectedSabores((prev) => {
      const updated = checked
        ? [...prev, value]
        : prev.filter((sabor) => sabor !== value);

      fetchSaborPrices(updated, selectedTamanho);
      return updated;
    });
  }

  function fetchSaborPrices(selectedSabores, selectedTamanho) {
    const saborPricesPromises = selectedSabores.map((sabor) => {
      const selectedSabor = sabores.find((secao) =>
        secao.tb_produtos.some((produto) => produto.descricao === sabor)
      );

      if (selectedSabor) {
        return Promise.all(
          selectedSabor.tb_produtos.map((produto) =>
            getProductTamanho(produto.cod_produto)
          )
        )
          .then((res) => {
            const valoresFiltrados = res.flatMap((response) =>
              response.data
                .filter(
                  (tamanho) =>
                    tamanho.descricao === selectedTamanho?.descricao // Verifica se o tamanho está selecionado
                )
                .map((tamanho) => parseFloat(tamanho.valor) || 0) // Converte para número, retornando 0 para valores inválidos
            );

            return valoresFiltrados.length > 0
              ? Math.max(...valoresFiltrados)
              : 0; // Retorna 0 se não houver valores válidos
          })
          .catch((error) => {
            console.error("Erro ao buscar tamanhos para o sabor: ", error);
            return 0; // Retorna 0 em caso de erro
          });
      }

      return Promise.resolve(0);
    });

    Promise.all(saborPricesPromises).then((prices) => {
      const maxSaborPrice = Math.max(0, ...prices);
      const basePrice = selectedTamanho?.valor || 0;
      const updatedPrice = basePrice + maxSaborPrice;
      setPrice(updatedPrice);
    });
  }

  useEffect(() => {
    const product = props.product;
    if (product) {
      const { tb_secaos, cod_produto, preco_venda_vista } = product;

      const initializedSecaos = tb_secaos.map((secao) => ({
        ...secao,
        quantidade: 0,
        tb_produtos: secao.tb_produtos.map((prod) => ({ ...prod, quantidade: 0 })),
      }));

      setData(initializedSecaos);
      setPrice(preco_venda_vista);
      setOriginalPriceProduct(preco_venda_vista);

      handleGetAdicionais(cod_produto);
      handleGetTamanhos(cod_produto);
      handleGetSabores(cod_produto);
    }
  }, [props.product]);

  function handleGetAdicionais(cod_produto) {
    if (cod_produto) {
      getProductAdd(cod_produto)
        .then((res) => {
          setAdditionals(res.data.product.tb_secaos);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setAdditionals([]);
  }

  function handleGetTamanhos(id) {
    if (id) {
      getProductTamanho(id)
        .then((res) => {
          setTamanhos(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setTamanhos([]);
  }

  function handleGetSabores(id) {
    if (id) {
      getProductSabores(id)
        .then((res) => {
          setSabores(res.data.product.tb_secaos);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setSabores([]);
  }



  useEffect(() => {
    const saboresString = selectedSabores.join(", ");
    setSaborString(saboresString);
  }, [selectedSabores]);

  function handleClearInput() {
    document.querySelectorAll("[type=radio]").forEach((item) => {
      item.checked = false;
    });
  }

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => []}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        keepMounted
        onKeyDown={(e) => (e.keyCode === 27 ? props.setModalSelectFalse() : "")}
      >
        <Box id="box-mui-select" className="" onClick={(e) => handleModal(e)}>
          <div className="select-modal">
            <button
              className="back-products"
              onClick={() => [props.setModalSelectFalse(), setObservation("")]}
            >
              <span>
                <i class="fa-solid fa-left-long"></i>
              </span>
              <span>{props.product.descricao}</span>
            </button>
            <div className="select-content">
              <div className="box-image">
                <img
                  style={{ width: "100%", height: "auto", maxHeight: "500px", marginBottom: "6px" }}
                  crossOrigin="anonymous"
                  src={
                    process.env.REACT_APP_API_URL + `/${props.product.imagem}`
                  }
                  alt=""
                />
              </div>

              {/* <p className="description">{props.product.description}</p> */}
              <section
                style={{
                  marginTop: "20px",
                }}
              >
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#555",
                    marginBottom: "10px",
                  }}
                >
                  Selecione um tamanho
                </p>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
                    gap: "15px",
                    marginTop: "10px",
                  }}
                >
                  {tamanhos.length > 0 && tamanhos?.map((tamanho, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "10px",
                        border: "1px solid #e0e0e0",
                        borderRadius: "5px",
                        backgroundColor: "#f9f9f9",
                        transition: "background-color 0.3s ease",
                        paddingBottom: "6px"
                      }}
                      onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f0")}
                      onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#f9f9f9")}
                    >
                      <input
                        type="radio"
                        name="tamanho"
                        id={`tamanho-${tamanho.cod_tamanho}`}
                        onChange={handleTamanhoChange}
                        value={tamanho.descricao}
                        style={{ marginRight: "10px" }}
                      />
                      <label htmlFor={`tamanho-${tamanho.cod_tamanho}`}>
                        {tamanho.descricao}
                      </label>
                      <span
                        style={{
                          fontWeight: "600",
                          color: "#333",
                          fontSize: "14px",
                        }}
                      >
                        R${parseFloat(tamanho.valor).toFixed(2)}
                      </span>
                    </div>
                  ))}
                </div>
              </section>
              {Array.isArray(sabores) && sabores.length > 0 && sabores.map((secao) => (
                <section
                  key={secao.cod_secao}
                  style={{
                    marginTop: "20px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#555",
                      marginBottom: "10px",
                    }}
                  >
                    Selecione um Sabor
                  </p>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
                      gap: "15px",
                      marginTop: "10px",
                    }}
                  >
                    {secao.tb_produtos.map((produto, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          padding: "10px",
                          border: "1px solid #e0e0e0",
                          borderRadius: "5px",
                          backgroundColor: "#f9f9f9",
                          transition: "background-color 0.3s ease",
                        }}
                        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f0")}
                        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#f9f9f9")}
                      >
                        <label>
                          <input
                            type="checkbox"
                            value={produto.descricao}
                            onChange={handleSaborChange}
                          />
                          {produto.descricao}
                        </label>
                      </div>
                    ))}
                  </div>
                </section>
              ))}


              <div className="additionals">
                <p
                  className="sub-title"
                  style={{
                    textAlign: "center",
                    borderBottom: "2px solid #f2f2f2",
                  }}
                >
                  Adicionais
                </p>
                {data?.map((item, index) => (
                  <>
                    {item.acompanhamento === "S" ? (
                      <>
                        {item.web_quantidade > 1 ? (
                          <section className="additional">
                            {
                              <p className="sub-title--additional">
                                {item.descricao} (Escolha até{" "}
                                {item.web_quantidade} itens)
                              </p>
                            }
                            {item.tb_produtos.map((subItem, subIndex) => (
                              <>
                                {item.cod_secao == subItem.cod_fk_secao ? (
                                  <div
                                    className="additional-item"
                                    key={subIndex + 1}
                                  >
                                    <div className="item-information">
                                      <span className="item-information-title">
                                        {subItem.descricao}
                                      </span>
                                      <br />
                                      <span className="item-information-price">
                                        R${" "}
                                        {parseFloat(
                                          subItem.preco_venda_vista
                                        )?.toFixed(2)}
                                      </span>
                                    </div>
                                    <div className="item-action">
                                      <div className="amount">
                                        <i
                                          onClick={() => {
                                            if (subItem.quantidade > 0) {
                                              handleSubAdditionals(index, subIndex, subItem);
                                            }
                                          }}
                                          className="fa fa-minus"
                                          style={{
                                            color: subItem.quantidade > 0 ? "black" : "gray", // Botão ativo apenas se a quantidade for maior que 0
                                            cursor: subItem.quantidade > 0 ? "pointer" : "not-allowed", // Cursor apropriado para o estado
                                            opacity: subItem.quantidade > 0 ? 1 : 0.5, // Opacidade para indicar o estado
                                          }}
                                        ></i>

                                        <span
                                          id={`product-${subItem.cod_produto}`}
                                        >
                                          {subItem.quantidade}
                                        </span>

                                        <i
                                          onClick={() => [
                                            hasIndexies.includes(subIndex) ||
                                              limitSection.includes(index)
                                              ? ""
                                              : handleAddAdditionals(
                                                index,
                                                subIndex,
                                                subItem,
                                                true
                                              ),
                                          ]}
                                          style={{
                                            color: `${hasIndexies.includes(subIndex) ||
                                              limitSection.includes(index)
                                              ? "gray"
                                              : ""
                                              }`,
                                            opacity: `${hasIndexies.includes(subIndex) ||
                                              limitSection.includes(index)
                                              ? 0.5
                                              : ""
                                              }`,
                                          }}
                                          className="fa fa-plus"
                                        ></i>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            ))}
                          </section>
                        ) : (
                          <section className="additional">
                            {
                              <p className="sub-title--additional">
                                {item.descricao} (Escolha um{" "}
                                {item.web_quantidade} item)
                              </p>
                            }
                            {item.tb_produtos.map((subItem, subIndex) => (
                              <>
                                {item.cod_secao == subItem.cod_fk_secao ? (
                                  <div
                                    className="additional-item"
                                    key={subIndex + 1}
                                  >
                                    <div className="item-information">
                                      <span className="item-information-title">
                                        {subItem.descricao}
                                      </span>
                                      <br />
                                      <span className="item-information-price">
                                        R${" "}
                                        {parseFloat(
                                          subItem.preco_venda_vista
                                        )?.toFixed(2)}
                                      </span>
                                    </div>
                                    <div className="item-action">
                                      <input
                                        type="radio"
                                        name={`additional--one-${item.cod_secao}`}
                                        onChange={() =>
                                          handleAddAdditionals(
                                            index,
                                            subIndex,
                                            subItem,
                                            false
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            ))}
                          </section>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ))}
              </div>
              <div className="observation">
                <p className="sub-title">Alguma Observação?</p>
                <div className="d-flex justify-content-end ">
                  <span>{observation.length}/150</span>
                </div>
                <textarea
                  name="observation"
                  rows="5"
                  placeholder="Adicione as Observações aqui"
                  onChange={(e) => setObservation(e.target.value)}
                  value={observation}
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                  type="text"
                  maxLength="150"
                />
              </div>
            </div>
            <div className="add-product">
              <div className="amount">
                <i
                  onClick={() => {
                    if (amountProduct > 1) {
                      setAmountProduct(amountProduct - 1);
                    }
                  }}
                  className="fa fa-minus"
                  style={{
                    color: amountProduct > 1 ? "black" : "gray",
                    cursor: amountProduct > 1 ? "pointer" : "not-allowed",
                    opacity: amountProduct > 1 ? 1 : 0.5,
                  }}
                ></i>
                <span>{amountProduct}</span>

                <i
                  onClick={() => setAmountProduct(amountProduct + 1)}
                  className="fa fa-plus"
                ></i>
              </div>
              <button
                className="add-button"
                onClick={() => [
                  setAmountProduct(1),
                  handleCart(),
                  setTamanhos([]),
                  setSabores([]),
                  setAdditionals([]),
                  setHasIndexies([]),
                  handleClearInput(),
                ]}
              >
                <span style={{ marginRight: "25px" }}>
                  <i className="fa fa-plus"></i>
                </span>
                Adicionar R$ {amountProduct * price?.toFixed(2)}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default SelectProductModal;
