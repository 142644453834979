import axios from "./BaseService";

const API_URL = process.env.REACT_APP_API_URL;
const SECAO_URL = `${API_URL}/secao/`;

export async function getSecoes(descricao, page, per_page, status, additional) {
  const codOrg = localStorage.getItem("cod_org");

  const secoesUrl = `${SECAO_URL}${descricao}?page=${page}&cod_org=${codOrg}&per_page=${per_page}&status=${"S"}&additional=${additional}`;
  const headers = {
    authorization: localStorage.getItem("token"),
  };

  const response = await axios.get(secoesUrl, { headers });
  return response.data;
}

export async function getTodasSecoes(token) {
  const headers = {
    authorization: token,
  };

  const response = await axios.get(SECAO_URL, { headers });
  return response.data;
}

export async function salvarSecao(cod_secao, novaSecao, token) {
  const headers = { authorization: token };

  let response;

  if (cod_secao) {
    response = await axios.patch(`${SECAO_URL}${cod_secao}`, novaSecao, {
      headers,
    });
  } else {
    response = await axios.post(`${SECAO_URL}`, novaSecao, { headers });
  }

  return response.data;
}

export async function excluirSecao(cod_secao, token, path_image) {
  const headers = { authorization: token };
  const response = await axios.delete(
    `${SECAO_URL}${cod_secao}/?path_image=${path_image}`,
    { headers }
  );
  return response.data;
}
