import React, { useEffect, useState, useRef } from "react";
import { deleteOrderTable, getOrders } from "../../components/helpers/Api";
import { useLocation, useNavigate } from "react-router-dom";
import { Load } from "../../components/widgets/spinner/Spinner";
import ModalConfirm from "../../components/partials/modals/ModalConfirm";
import DetalhesPedidoFechado from "./DetalhesPedidoFechado";
import ReactToPrint from "react-to-print"; // Importando o ReactToPrint
import {
  getPaymentMethodByMenu
} from "../../components/helpers/Api";
import RelatorioPrint from "../../utils/Relatorio"
import Pagination from "../../components/Pagination/Pagination";

const ListarPedidos = ({ ...props }) => {
  const [searchOrder, setSearchOrder] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const defaultLocation = useLocation();
  const [loadingModalConfirm, setLoadingModalConfirm] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [id, setId] = useState("");
  const [detailtView, setDetailView] = useState(false);
  const [codMesa, setCodMesa] = useState("");
  const [closedOrder, setClosedOrder] = useState({});
  const [cancelOrder, setCancelOrders] = useState(0);
  const [sum, setSum] = useState(0);
  const [relatorio, setRelatorio] = useState([]);
  const [count, setCount] = useState(0);
  const history = useNavigate();
  const printRef = useRef();
  const printRef2 = useRef()

  const getPage = (location) => {
    if (!location) {
      location = defaultLocation;
    }
    return new URLSearchParams(location.search).get("page");
  };

  useEffect(() => {
    setPage(getPage(history));
  }, [history]);

  const [page, setPage] = useState(getPage());

  useEffect(() => {
    const fetchAllData = async () => {
      const orders = await relatorioData();
      const d = handleGetAllOrders()
      if (orders) {
        const relatorioData = await getReleatorio(orders);
        setRelatorio(relatorioData);
      }
    };

    fetchAllData();
  }, []);

  async function handleGetAllOrders() {

    let data = await getOrders(searchOrder, page || 1, 10, "DESC", "todos")
    setData(data.data.data.rows)
    setCount(data.data.data.count)
    calculateTotalValue(data.data.data.rows)

    return data.data.data.rows
  }
  async function relatorioData() {

    let data = await getOrders(searchOrder, page || 1, 1000, "DESC", "todos")
    setData(data.data.data.rows)
    setCount(data.data.data.count)
    calculateTotalValue(data.data.data.rows)

    return data.data.data.rows
  }
  function calculateTotalValue(data) {

    let s = data.reduce((sum, item) => sum + (item.valor_total || 0), 0);
    setSum(s);
  }

  const getReleatorio = async (data) => {
    let formasPagamento = await getPaymentMethodByMenu();
    formasPagamento = formasPagamento.data.data.rows

    const today = new Date();
    today.setUTCHours(0, 0, 0, 0);
    const todayISO = today.toISOString();

    const pedidosDoDia = data.filter((pedido) => {
      const dataPedido = new Date(pedido.datahorainicio);
      return dataPedido >= today;
    });

    const totalPorFormaPagamento = {};

    formasPagamento.forEach(({ id, descricao }) => {
      totalPorFormaPagamento[id] = { descricao, total: 0 };
    });

    pedidosDoDia.forEach((pedido) => {
      const formaPagamento = pedido.tb_ipedidomesas[0]?.forma_pagamento
      const valorTotal = pedido.valor_total || 0;

      if (totalPorFormaPagamento[formaPagamento]) {
        totalPorFormaPagamento[formaPagamento].total += valorTotal;
      } else {
        totalPorFormaPagamento[formaPagamento] = { descricao: formaPagamento, total: valorTotal };
      }
    });
    delete totalPorFormaPagamento.undefined
    return totalPorFormaPagamento;
  };


  const handleDeleteOrder = () => {
    setLoadingModalConfirm(true);
    deleteOrderTable(id, codMesa)
      .then((res) => {
        if (res.status === 200) {
          setOpenModalConfirm(false);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoadingModalConfirm(false);
      });
  };

  return (
    <>
      {detailtView ? (
        <DetalhesPedidoFechado
          data={closedOrder}
          handleRequest={() => handleGetAllOrders()}
          setFalseDetailView={() => setDetailView(false)}
        />
      ) : (
        <>
          {loading ? (
            <Load />
          ) : (
            <main className="">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                  <h2 className="h4">Todos os Pedidos</h2>
                </div>
                <div className="btn-toolbar mb-2 mb-md-0">
                  <div className="d-inline-flex align-items-center">
                    <button
                      id="btnNovoParceiro"
                      className="btn btn-primary animate-up-2"
                      data-bs-toggle="modal"
                      data-bs-target="#modalParceiro"
                    >
                      Novo Pedido
                    </button>
                  </div>
                  <div className="btn-group ms-2 ms-lg-3">
                    <input
                      type="text"
                      className="form-control"
                      id="search"
                      placeholder="Digite o nome do cliente"
                      onChange={(e) => setSearchOrder(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div class="summary">
                <div class="card">
                  <h2>{data.length}</h2>
                  <p>Total de pedidos</p>
                </div>
                <div class="card">
                  <h2>{cancelOrder}</h2>
                  <p>Pedidos cancelados</p>
                </div>
                <div class="card">
                  <h2>R$ {sum}</h2>
                  <p>Total movimentado</p>
                </div>
              </div>
              <div className="d-flex justify-content-between mb-3">
                <ReactToPrint
                  trigger={() => (
                    <button className="btn btn-primary" style={{ marginRight: '10px' }}>
                      Imprimir Relatório
                    </button>
                  )}
                  content={() => printRef2.current}
                />
                <ReactToPrint
                  trigger={() => <button className="btn btn-success">Exportar para PDF</button>}
                  content={() => printRef.current}
                />
              </div>

              {/* Conteúdo para impressão do relatório */}
              <div style={{ display: "none" }}>
                <RelatorioPrint ref={printRef2} dados={relatorio} />
              </div>

              {/* Conteúdo para exibição na interface */}
              <div
                ref={printRef}
                className="card card-body border-0 shadow table-wrapper table-responsive"
              >
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th className="border-gray-200 text-center">Número</th>
                      <th className="border-gray-200 text-center">Cliente</th>
                      <th className="border-gray-200 text-center">Status</th>
                      <th className="border-gray-200 text-center">Forma de pagamento</th>
                      <th className="border-gray-200 text-center">Origem</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item) => (
                      <tr
                        key={item.cod_pedidomesa}
                        role="button"
                        onClick={() => [
                          setClosedOrder(item),
                          setDetailView(true),
                        ]}
                      >
                        <td className="text-center">
                          #{item.numero_pedidomesa}
                        </td>
                        <td className="text-center">{item.nome_cliente}</td>
                        <td className="text-center">{item.situacao}</td>
                        <td className="text-center">
                          {item?.tb_ipedidomesas[0].forma_pagamento}
                        </td>
                        <td className="text-center">
                          {item?.origem}
                        </td>
                      </tr>
                    ))}
                  </tbody>

                </table>
                <Pagination count={count} onPageChange={page} />

                <ModalConfirm
                  title="Tem certeza que deseja deletar este endereço?"
                  open={openModalConfirm}
                  setModalCrudFalse={() => setOpenModalConfirm(false)}
                  handleRequest={() => handleDeleteOrder()}
                  loading={loadingModalConfirm}
                  textBack="Cancelar"
                  textSubmit="Deletar"
                />
              </div>
            </main>
          )}
        </>
      )}
    </>
  );
};

export default ListarPedidos;
