import React, { useState, useEffect } from 'react';
import { getDataMenu } from "../components/helpers/Api";

const RelatorioPrint = React.forwardRef(({ dados }, ref2) => {
    const [empresa, setEmpresa] = useState({});
    const [loading, setLoading] = useState(true);
    console.log(dados)
    const formatDate = (date) => {
        if (!date || isNaN(new Date(date))) return "Data Inválida";
        return new Date(date).toLocaleDateString();
    };

    const formatTime = (time) => {
        if (!time || isNaN(new Date(time))) return "Hora Inválida";
        return new Date(time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    useEffect(() => {
        const handleGetDataMenu = async () => {
            setLoading(true);
            try {
                const res = await getDataMenu();
                const data = res.data.data;
                setEmpresa({
                    nome: data.razao_social,
                    taxaEntrega: data.taxa_entrega,
                    cidade: data.cidade,
                    logradouro: data.logradouro,
                    bairro: data.bairro,
                    uf: data.uf,
                    numero: data.numero,
                });
            } catch (error) {
                console.error("Erro ao buscar dados da empresa:", error);
            } finally {
                setLoading(false);
            }
        };

        handleGetDataMenu();
    }, []);

    const currentDate = formatDate(new Date());
    const currentTime = formatTime(new Date());

    const styles = {
        printerTicket: {
            display: 'table',
            width: '100%',
            maxWidth: '400px',
            fontWeight: 'lighter',
            lineHeight: '1.3em',
            fontFamily: 'Tahoma, Geneva, sans-serif',
            color: 'black',
            padding: '3px 8px',
            fontSize: '10px',
        },
        title: {
            fontSize: '22px',
            padding: '10px 0',
            textAlign: 'center',
        },
        thTd: {
            borderBottom: '1px dashed #707070',
            padding: '5px 0',
            fontSize: '10px',
        },
        ttu: {
            textTransform: 'uppercase',
            fontSize: '10px',
            textAlign: 'center',
        },
        center: {
            textAlign: 'center',
            fontSize: '10px',
        },
    };

    return (
        <div ref={ref2} style={styles.printerTicket}>
            {loading ? (
                <p>Carregando dados da empresa...</p>
            ) : (
                <>
                    <table style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <th style={{ ...styles.title, ...styles.thTd }} colSpan="3">
                                    {empresa.nome || 'Empresa Não Informada'}
                                    <p style={{ fontSize: "10px" }}>
                                        {empresa.logradouro}, {empresa.numero} - {empresa.bairro}, {empresa.cidade} - {empresa.uf}
                                    </p>
                                </th>
                            </tr>
                            <tr>
                                <th colSpan="3" style={styles.thTd}>
                                    {currentDate} - {currentTime}
                                </th>
                            </tr>
                            <tr>
                                <th style={{ ...styles.ttu, ...styles.thTd }} colSpan="3">
                                    Movimentações diárias
                                </th>
                            </tr>
                            <tr>
                                <th style={styles.thTd}>Forma de Pagamento</th>
                                <th style={styles.thTd}>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.values(dados).map(({ descricao, total }, index) => (
                                <tr key={index}>
                                    <td style={styles.thTd}>{descricao}</td>
                                    <td style={styles.thTd}>
                                        {total.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div style={styles.center}>
                        <p>www.sistemacsistemas.com.br</p>
                    </div>
                </>
            )}
        </div>
    );
});

export default RelatorioPrint;
