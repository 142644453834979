/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "../../components/Pagination/Pagination";
import { getSecoes, excluirSecao } from "../../services/SecaoService";
import CadSecaoRow from "./CadSecaoRow";
import CadSecaoModal from "./CadSecaoModal/CadSecaoModal";
import PesquisaSecao from "./PesquisaSecao";
import { Load } from "../../components/widgets/spinner/Spinner";

function CadSecao() {
  const [pesquisaSecoes, setPesquisaSecao] = useState("");
  const [secoes, setSecoes] = useState([]);
  const [count, setCount] = useState(0);
  const defaultLocation = useLocation();
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [pathImage, setPathimage] = useState("");

  const DEFAULT_SECAO = {
    descricao: "",
    comissao: "",
    listar_secao: "S",
  };

  const [editSecao, setEditSecao] = useState(DEFAULT_SECAO);

  function getPage(location) {
    if (!location) {
      location = defaultLocation;
    }

    return new URLSearchParams(location.search).get("page");
  }

  function onNovaSecaoClick(event) {
    setEditSecao(DEFAULT_SECAO);
  }

  function getSecoesCall() {
    setLoading(true);
    getSecoes(pesquisaSecoes, page || 1, 10, "all", "all")
      .then((result) => {
        setSecoes(result.rows);
        setCount(result.count);
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const location = useNavigate();

  useEffect(() => {
    setPage(getPage(location));
  }, [location]);

  const [page, setPage] = useState(getPage());

  useEffect(() => {
    getSecoesCall();
  }, [page, pesquisaSecoes]);

  function onAlterarClick(event) {
    const cod_secao = event.target.id.replace("alterar", "");
    const secao = secoes.find((e) => e.cod_secao == cod_secao);
    setEditSecao({ ...secao });
  }

  function onExcluirClick(event) {
    const cod_secao = event.target.id.replace("excluir", "");

    excluirSecao(cod_secao, token, pathImage)
      .then((secao) => {
        getSecoesCall();
      })
      .catch((err) =>
        console.error(err.response ? err.response.data : err.message)
      );
  }

  function onModalSubmit(event) {
    getSecoesCall();
  }

  function onPesquisaSecaoChange(event) {
    setPesquisaSecao(event.target.value);
  }

  return (
    <React.Fragment>
      <main className="">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-md-0">
            <h2 className="h4">Cadastro Seção</h2>
          </div>
          <div className="btn-toolbar mb-2 mb-md-0">
            <div className="d-inline-flex align-items-center">
              <button
                id="btnNovaSecao"
                className="btn btn-primary animate-up-2"
                data-bs-toggle="modal"
                data-bs-target="#modalSecao"
                onClick={onNovaSecaoClick}
              >
                <svg
                  className="icon icon-xs me-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                Nova Seção
              </button>
            </div>
            <div className="btn-group ms-2 ms-lg-3">
              <PesquisaSecao
                placeholder="Pesquisa Seção"
                onChange={onPesquisaSecaoChange}
              />
            </div>
          </div>
        </div>
        <div className="card card-body border-0 shadow table-wrapper table-responsive">
          {loading ? (
            <Load />
          ) : (
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="border-gray-200">Código</th>
                  <th className="border-gray-200">Imagem</th>
                  <th className="border-gray-200">Descrição</th>
                  <th className="border-gray-200">Comissão</th>
                  <th className="border-gray-200">Ações</th>
                </tr>
              </thead>
              <tbody>
                {secoes.map((secao) => (
                  <CadSecaoRow
                    key={secao.cod_secao}
                    data={secao}
                    onAlterarClick={onAlterarClick}
                    onExcluirClick={onExcluirClick}
                    setPathImage={(path) => setPathimage(path)}
                  />
                ))}
              </tbody>
            </table>
          )}
          <Pagination count={count} />
        </div>
      </main>
      <CadSecaoModal data={editSecao} onSubmit={onModalSubmit} />
    </React.Fragment>
  );
}

export default CadSecao;
