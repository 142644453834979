import React from "react";
import { Box, Modal } from "@mui/material";
import "./Modals.scss";

const InformationModal = ({ open, setModalCrudFalse, orgData }) => {
  function handleModal(e) {
    if (e.nativeEvent.target.id === "box-mui-information") {
      setModalCrudFalse();
    }
  }

  return (
    <>
      <Modal
        open={open}
        onClose={() => []}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        keepMounted
        onKeyDown={(e) => (e.keyCode === 27 ? setModalCrudFalse() : "")}
      >
        <Box
          id="box-mui-information"
          className=""
          onClick={(e) => handleModal(e)}
        >
          <div className="box-content">
            <div className="information-modal">
              <div className="box-title">
                <i
                  onClick={() => setModalCrudFalse()}
                  className="fa-solid fa-xmark"
                ></i>
              </div>
              <div className="box-items">
                <p className="sub-title">Telefone</p>
                <p className="information-text"> {orgData.telefone} </p>
              </div>
              <div className="box-items">
                <p className="sub-title">Endereço</p>
                <address className="information-text">
                  {`${orgData.logradouro}, ${orgData.numero}, ${orgData.bairro} - ${orgData.cidade} - ${orgData.uf}`}
                </address>
              </div>
              <div className="box-items">
                <p className="sub-title">Opções de Entrega</p>
                <span className="text-with-background">
                  <i className="fa fa-motorcycle"></i> <span>Delivery</span>
                </span>
                <span className="text-with-background">
                  <i className="fa fa-shopping-bag"></i> <span>Retirada</span>
                </span>
              </div>
              <div className="box-items">
                <p className="sub-title">Opções de Pagamento na entrega</p>
                <span className="text-with-background">Dinheiro</span>
              </div>
              <div className="box-items">
                <p className="sub-title">Cartões de Crédito aceitos</p>
                <span className="text-with-background">Visa</span>
                <span className="text-with-background">MasterCard</span>
              </div>
              <div className="box-items">
                <p className="sub-title">Cartões de Débito aceitos</p>
                <span className="text-with-background">Visa</span>
                <span className="text-with-background">MasterCard</span>
              </div>
              <div className="box-items">
                <p className="sub-title">PIX</p>
              </div>
              <div className="box-items">
                <p style={{ color: "red" }}>Pedido Mínimo R$ {orgData.preco_minimo}</p>
              </div>

            </div>
            <div className="end-modal">
              <button onClick={() => setModalCrudFalse()}>
                Fechar <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default InformationModal;
